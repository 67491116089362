import { ref, reactive, toRefs, computed } from '@vue/composition-api'
import { fetchUserStats } from '../api/fetchUserStats'
import { sendAnswer } from '../api/sendAnswer'
import { fetchDataset } from '../api/fetchDataset'
import { fetchFinalStatus } from '../api/fetchFinalStatus'

const state = reactive({
  /* stats */
  statsIsLoading: false,
  statsLoadingError: null,
  /* answer */
  answerSending: false,
  answerError: null,
  answerSuccess: null,
  /* final */
  finalStatus: null,
  userStats: null,
})

const issues = ref([])
const taskSolved = ref(0)

export const issuesDescr = {
  covid19_1: {
    difficulty: 1,
    title: 'Covid-19',
    description: 'Изучи, как коронавирус развивается в разных частях света',
    image: '/images/card-covid-19.png',
    answerType: 'string',
    placeholder: 'Например, North America',
  },
  gender: {
    difficulty: 2,
    title: 'Gender',
    description: 'Помоги восстановить пропущенные данные в анкетах клиентов',
    image: '/images/card-gender.png',
    answerType: 'file',
  },
  predict: {
    difficulty: 3,
    title: 'Predict',
    description:
      'Определи уровень зарплаты сотрудников на основе данных об их опыте',
    image: '/images/card-predict.png',
    answerType: 'json',
  },
  paris: {
    difficulty: 2,
    title: 'Paris',
    description: 'Помоги разделить объекты недвижимости на кластеры',
    image: '/images/card-paris.png',
    answerType: 'string',
    placeholder: 'Например, 60',
  },
  news: {
    difficulty: 3,
    title: 'News',
    description: 'Обучи нейросеть для классификации новостей',
    image: '/images/card-news.png',
    answerType: 'file',
  },
  airport: {
    difficulty: 2,
    title: 'Airport',
    description: 'Предскажи пассажиропоток аэропорта',
    image: '/images/card-airport.png',
    answerType: 'json',
  },
  exam: {
    title: '',
    description: '',
    image: '',
    answerType: 'file',
    isFinal: true,
    url: 'solve_essay',
    inputLabel: 'файл с текстом в doc, pdf, txt или других текстовых форматах',
  },
  mpp: {
    title: '',
    description: '',
    image: '',
    answerType: 'file',
    isFinal: true,
    url: 'solve_main',
    inputLabel: '',
  },
}

export const orderedIssues = Object.entries(issuesDescr)
  .filter(([key, val]) => !val.isFinal)
  .sort((a, b) => a[1].difficulty - b[1].difficulty)
  .map((item) => item[0])

export default function useGame() {
  const updateUserStats = async () => {
    state.statsIsLoading = true
    try {
      const { all_tasks_ids, solved_tasks_ids, ...userStats } =
        await fetchUserStats()
      taskSolved.value = solved_tasks_ids.length
      issues.value = orderedIssues.map((item) => {
        return {
          id: item,
          isComplete: solved_tasks_ids.includes(item),
          difficulty: issuesDescr[item].difficulty,
          title: issuesDescr[item].title,
          description: issuesDescr[item].description,
          image: issuesDescr[item].image,
        }
      })
    } catch (e) {
      state.statsLoadingError = e
    } finally {
      state.statsIsLoading = false
    }
  }

  const answerIssue = async (payload, url = 'solve') => {
    state.answerSuccess = null
    state.answerError = null
    state.answerSending = true
    const formData = new FormData()
    if (payload.file) {
      formData.append('file', payload.file)
    }
    if (payload.data) {
      formData.append('data', payload.data)
    }
    formData.append('task_id', payload.task_id)
    try {
      const { data } = await sendAnswer(formData, url)
      state.answerSuccess = data
    } catch (e) {
      state.answerError = e.response
    } finally {
      state.answerSending = false
    }
  }

  const loadDataset = async (taskId) => {
    try {
      const response = await fetchDataset(taskId)
    } catch (e) {
      // state.error = e
    } finally {
    }
  }
  const loadFinalStatus = async () => {
    try {
      const response = await fetchFinalStatus()
      state.finalStatus = response
    } catch (e) {
      console.log(e)
    }
  }
  const loadUserStats = async () => {
    try {
      const response = await fetchUserStats()
      state.userStats = response
    } catch (e) {
      console.log(e)
    }
  }

  const issueslanding = orderedIssues.map((item) => {
    return {
      // id: item,
      difficulty: issuesDescr[item].difficulty,
      title: issuesDescr[item].title,
      description: issuesDescr[item].description,
      image: issuesDescr[item].image,
    }
  })

  return {
    ...toRefs(state),
    updateUserStats,
    issues,
    answerIssue,
    loadDataset,
    taskSolved,
    issueslanding,
    loadFinalStatus,
    loadUserStats,
  }
}
