import VueGtm from 'vue-gtm'
import * as Sentry from '@sentry/vue'
import { TweenMax, TweenLite, TimelineMax } from 'gsap'
import VueObserveVisibility from 'vue-observe-visibility'
import VModal from 'vue-js-modal'
import VueTippy, { TippyComponent } from 'vue-tippy'

export default function (Vue, router) {
  Vue.use(VueObserveVisibility)
  Vue.use(VModal)
  Vue.use(VueTippy)
  Vue.component('tippy', TippyComponent)

  // Sentry
  const sentryToken = process.env.VUE_APP_SENTRY
  if (sentryToken) {
    Sentry.init({
      Vue: Vue,
      dsn: sentryToken,
      logErrors: true,
      attachProps: true,
    })
    Sentry.setContext('version', {
      version: process.env.VUE_APP_VERSION,
    })
  }

  // GTM
  const gtmToken = process.env.VUE_APP_GTM
  if (gtmToken) {
    Vue.use(VueGtm, {
      id: gtmToken,
      // queryParams: {
      //   // Add url query string when load gtm.js with GTM ID (optional)
      //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
      //   gtm_preview: "env-4",
      //   gtm_cookies_win: "x",
      // },
      defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    })
  }

  // Gsap
  Vue.prototype.$tween = TweenMax
  Vue.prototype.$tweenLite = TweenLite
  Vue.prototype.$timeline = TimelineMax
}
