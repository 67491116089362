import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import { fetchUser } from '../api/fetchUser'
import { patchUser } from '../api/patchUser'
import { userLogout } from '../api/userLogout'

const state = reactive({
  isLoading: false,
  error: null,
  success: null,
})

const token = ref(localStorage.getItem('token'))
const user = ref(null)

export default function useUser() {
  const setAuthToken = (val) => {
    token.value = val
    localStorage.setItem('token', val)
  }

  const updateUser = async () => {
    state.isLoading = true
    try {
      const { data } = await fetchUser()
      user.value = data
    } catch (e) {
      state.error = e
    } finally {
      state.isLoading = false
    }
  }

  const sendUserData = async ({ phone, firstName }) => {
    state.error = null
    state.success = null
    state.isLoading = true
    try {
      const response = await patchUser({
        userId: user.value.id,
        payload: { phone, first_name: firstName },
      })
      state.success = response
    } catch (e) {
      state.error = e
    } finally {
      state.isLoading = false
    }
  }

  const logout = async () => {
    try {
      console.log('[use-user] logout start')
      await userLogout(token.value)
      window.location.reload()
    } catch (e) {
      console.log('[use-user] logout error', e)
    } finally {
      token.value = null
      user.value = null
      localStorage.clear()
      console.log('[use-user] logout done')
    }
  }

  const isAuthenticated = computed(() => {
    return user.value?.id > 0
  })

  return {
    ...toRefs(state),
    token,
    setAuthToken,
    updateUser,
    user,
    isAuthenticated,
    logout,
    sendUserData,
  }
}
