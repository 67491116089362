<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
.version {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 0, 0, 0.5);
  color: white;
  font-size: 10px;
  padding: 0px 4px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app > div.vue-notification-group > span > div {
  overflow: visible;
  margin-bottom: 8px;
}

#app > div.vue-notification-group {
  z-index: 9999;
  padding-top: 84px;
}

.g-notification.vue-notification-template {
  @apply relative font-graphik_lv_regular;
  padding: 8px 12px;
  color: #ffffff;
  border-radius: 4px;
  background: #313131;
  padding-right: 32px;

  & .notification-title {
    @apply font-normal;
    font-size: 12px;
    line-height: 16px;
  }

  &.success {
    border-left: 8px solid #00cdb6;
  }
  &.info {
  }
  &.error {
    border-left: 8px solid #ff6c6c;
  }
}
</style>

<template>
  <div id="app">
    <notifications
      classes="g-notification"
      position="top right"
      width="156px"
    />
    <div class="version">
      <small> v{{ version }} </small>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
  },
}
</script>
