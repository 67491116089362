import Vue from 'vue'
import VueRouter from 'vue-router'
import useGame from '../composables/useGame'
import useUser from '../composables/useUser'

Vue.use(VueRouter)

// Components

// Guards
const needAuth = (to, from, next) => {
  const { isAuthenticated } = useUser()
  if (!isAuthenticated.value) {
    next({ name: 'Landing' })
  } else {
    const { updateUserStats, loadFinalStatus } = useGame()
    Promise.all([updateUserStats(), loadFinalStatus()]).then((val) => {
      next()
    })
  }
}
// const needGame = () => {}
// const needGameFinished = () => {}

const routes = [
  {
    path: '/timer',
    component: () =>
      import(
        /* webpackChunkName: "timer" */ '@/components/UI/DigitalTimer.vue'
      ),
    props: { interval: 48 * 60 * 60 },
  },
  {
    path: '',
    component: () =>
      import(/* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'),
    props: { isGame: false },
    children: [
      {
        path: '/',
        name: 'Landing',
        // prettier-ignore
        component: () => import(/* webpackChunkName: "landing" */ '@/pages/Landing/index.vue'),
        beforeEnter: (to, from, next) => {
          const { isAuthenticated } = useUser()
          const { loadFinalStatus } = useGame()
          loadFinalStatus()
          if (isAuthenticated.value) {
            next({ name: 'Issues' })
          } else {
            next()
          }
        },
      },
    ],
  },
  {
    path: '',
    component: () =>
      import(/* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'),
    props: { isGame: true },
    children: [
      {
        path: '/results',
        name: 'Results',
        // prettier-ignore
        component: () => import(/* webpackChunkName: "results" */ '@/pages/Results/index.vue'),
        beforeEnter: needAuth,
      },
      {
        path: '/issues',
        name: 'Issues',
        // prettier-ignore
        component: () => import(/* webpackChunkName: "issues" */ '@/pages/Issues/index.vue'),
        beforeEnter: needAuth,
      },
      {
        path: '/issues/:id',
        name: 'IssuesItem',
        // prettier-ignore
        component: () => import(/* webpackChunkName: "issues-item" */ '@/pages/IssuesItem/index.vue'),
        beforeEnter: needAuth,
      },
      // {
      //   path: '/final',
      //   name: 'FinalPage',
      //   // prettier-ignore
      //   component: () => import(/* webpackChunkName: "final-page" */ '@/pages/FinalPage/index.vue'),
      //   beforeEnter: needAuth,
      // },
      {
        path: '/finalists',
        name: 'EndingPage',
        // prettier-ignore
        component: () => import(/* webpackChunkName: "ending-page" */ '@/pages/EndingPage/index.vue'),
        beforeEnter: (to, from, next) => {
          const { isAuthenticated } = useUser()
          if (!isAuthenticated.value) {
            next()
          } else {
            const { updateUserStats, loadFinalStatus } = useGame()
            Promise.all([updateUserStats(), loadFinalStatus()]).then((val) => {
              next()
            })
          }
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
