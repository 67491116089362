import '@/setup/installCompositionApi.js'
import '@/setup/installVueNotifications.js'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import auth from '@/setup/auth.js'
import components from './setup/components'
import helpers from '@/setup/helpers.js'
import dateFilter from '@/setup/filters'
import libs from '@/setup/libs.js'
import '@/css/app.scss'
import '@/css/prism.css'
import 'animate.css'

import 'windi.css'
import useUser from './composables/useUser'

Vue.config.productionTip = false

// Setup stuff
auth(Vue, router)
components(Vue)
helpers(Vue, router)
libs(Vue, router)
Vue.filter('date', dateFilter)

const { updateUser } = useUser()

updateUser().then(() => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app')
})
