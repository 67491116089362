import axios from 'axios'

export const userLogout = async (token) => {
  // logout вызывается без префикса /api, дефолтный baseURL не переопределяется, создаю новый инстанс
  const instance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: { Authorization: `Bearer ${token}` },
  })
  const response = await instance.post('/logout')
  return response
}
