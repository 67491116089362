import axios from 'axios'
import useUser from '../composables/useUser'

export default function (Vue, router, store) {
  let authToken
  const { token, setAuthToken } = useUser()
  if (token.value) {
    authToken = token.value
  }
  // Axios
  axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + '/api'
  axios.defaults.withCredentials = false
  axios.defaults.headers['Accept'] = 'application/json'
  axios.defaults.headers['Content-Type'] = 'application/json'

  // Auth
  const uriParams = new URLSearchParams(window.location.search)
  const tokenFromRedirect = uriParams.get('token')
  if (tokenFromRedirect && !authToken) {
    authToken = tokenFromRedirect
  }
  console.log('[auth.js]', authToken)

  if (authToken) {
    console.log('[auth.js] Token found!', authToken)
    setAuthToken(authToken)
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
    if (window.opener) {
      console.log('[auth.js] isOPENER window.opener.postMessage...')
      window.opener.postMessage({ token: authToken }, '*')
    } else {
      console.log('[auth.js] noOPENER window.postMessage')
      window.postMessage({ token: authToken }, '*')
    }
  }

  // Axios interceptors
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // prettier-ignore
      const allowed = ['/', '/password-reset', '/signup', '/signin', '/privacy', '/test']
      if (error && error.response) {
        // prettier-ignore
        if (error.response.status === 401 && !allowed.includes(window.location.pathname)) {
          let msg = '401. Требуется авторизация'
          console.log(msg, error.toString())
          /* Vue.notify({
            title: 'Authorization',
            text: msg,
            position: 'top right',
            type: 'error'
          }) */
        }
        if (error.response.status === 400) {
          let msg = '400. Сервер не смог обработать запрос'
          console.log(msg, error.toString())
        }
        if (error.response.status === 500) {
          let msg = '500. Что-то пошло не так...'
          console.log(msg, error.toString())
        }
        if (error.response.status === 403) {
          let msg = '403. Доступ запрещен!'
          console.log(msg, error.toString())
        }
        if (error.response.status === 404) {
          let msg = '404. Не найдено'
          console.log(msg, error.toString())
        }
      }

      throw error
    }
  )
}
